import cn from 'classnames'

import { MethodIconStyled } from './MethodIcon.styled'
import { PaymentMethod } from 'mmfintech-commons-types'
import { getPaymentMethodImageUrl, isValidString } from 'mmfintech-commons'

import BankwireImage from '../../assets/methods/bank-transfer.png'
import CreditCardImage from '../../assets/methods/credit-card.png'
import InstantBankTransferImage from '../../assets/methods/instant-bank-transfer.png'
import { configuration } from 'mmfintech-checkout-commons'

type MethodIconProps = {
  method: PaymentMethod
  selected?: boolean
}

export const MethodIcon = ({ method, selected }: MethodIconProps) => {
  const getImage = () => {
    if (isValidString(method)) {
      switch (method) {
        case 'EMONEY':
        case 'BANKWIRE':
        case 'LOCAL_BANKWIRE':
          return <img src={BankwireImage} alt='' className='local' />

        case 'COMMUNITY_INSTANT_BANK_TRANSFER':
        case 'DIRECT_INSTANT_BANK_TRANSFER':
        case 'INSTANT_BANK_TRANSFER':
          return <img src={InstantBankTransferImage} alt='' className='local' />

        case 'CREDIT_CARD':
          return <img src={CreditCardImage} alt='' className='local' />

        // case 'CRYPTO':
        // case 'INSTANT_CRYPTO':
        //   return <img src={CryptoImage} alt='' className='local' />

        default:
          return <img src={`${configuration.readBackendConfig()}${getPaymentMethodImageUrl(method)}`} alt='' />
      }
    }

    return null
  }

  return <MethodIconStyled className={cn({ selected })}>{getImage()}</MethodIconStyled>
}
