import styled from 'styled-components'

export const MethodIconStyled = styled.span`
  position: relative;
  width: 4rem;
  height: 4rem;
  padding: 0;
  margin: 0;

  border-radius: 0;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  & > img {
    max-width: 100%;
    max-height: 100%;

    &.local {
      width: 80%;
    }
  }
`
